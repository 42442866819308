import {
  type Role,
  ROLE_VALUES,
  PUBLIC_IMAGES_PATHS,
} from "@cloudifybiz/lighthouse-core/constants";
import { ipAPIResponseSchema } from "@cloudifybiz/lighthouse-core/zod/schema/misc";
import type {
  PickDefinedKeys,
  Prettify,
} from "@cloudifybiz/lighthouse-core/types";

/**
 * @description Check if the current role is allowed to perform an action
 * @param currentRole The current role
 * @param checkAgainst The role to check against
 * @returns {boolean} Whether the current role is allowed to perform an action
 */
export const allowedWithRole = (currentRole: Role, checkAgainst: Role) => {
  const currentRoleIndex = ROLE_VALUES.indexOf(currentRole);
  const checkAgainstIndex = ROLE_VALUES.indexOf(checkAgainst);
  return currentRoleIndex <= checkAgainstIndex;
};

/**
 * Wait for the given milliseconds
 * @param milliseconds The given time to wait
 * @returns  A fulfilled promise after the given time has passed
 */
function waitFor(milliseconds: number) {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
}

/**
 * Execute a promise and retry with exponential backoff
 * based on the maximum retry attempts it can perform
 * @param promise promise to be executed
 * @param maxRetries The maximum number of retries to be attempted
 * @param onRetry callback executed on every retry
 * @returns The result of the given promise passed in
 */
export function retry<T>(
  promise: () => Promise<T>,
  maxRetries: number,
  onRetry?: () => void,
): Promise<T> {
  async function retryWithBackoff(retries: number) {
    try {
      // Make sure we don't wait on the first attempt
      if (retries > 0) {
        // Here is where the magic happens.
        // on every retry, we exponentially increase the time to wait.
        // Here is how it looks for a `maxRetries` = 4
        // (2 ** 1) * 100 = 200 ms
        // (2 ** 2) * 100 = 400 ms
        // (2 ** 3) * 100 = 800 ms
        const timeToWait = 2 ** retries * 100;
        console.log(`waiting for ${timeToWait}ms...`);
        await waitFor(timeToWait);
      }
      return await promise();
    } catch (e) {
      // only retry if we didn't reach the limit
      // otherwise, let the caller handle the error
      if (retries < maxRetries) {
        onRetry && onRetry();
        return retryWithBackoff(retries + 1);
      } else {
        console.warn("Max retries reached. Bubbling the error up");
        throw e;
      }
    }
  }

  return retryWithBackoff(0);
}

/**
 * @description Get the public URL for an image with the given path.
 * If the path is already a public URL, it will be returned as is.
 * @example
 * // Returns https://lighthouse-public.s3.us-east-2.amazonaws.com/app-logos/abc.png
 * getPublicImageURL("abc.png", "APP_LOGO")
 * @example
 * // Returns https://example.com/abc.png
 * getPublicImageURL("https://example.com/abc.png", "APP_LOGO")
 * @example
 * // Returns data:image/png;base64,iVBORw0KGgoAAAANSUhEUg
 * getPublicImageURL("data:image/png;base64,iVBORw0KGgoAAAANSUhEUg")
 * @param path - The path to the image
 * @param type - The type of image
 * @returns The public URL for the image
 */
export const getPublicImageURL = (
  path: string,
  type: keyof typeof PUBLIC_IMAGES_PATHS,
) => {
  if (!process.env["PUBLIC_REGIONAL_DOMAIN_NAME"])
    throw new Error("PUBLIC_REGIONAL_DOMAIN_NAME is not defined");
  if (
    path.startsWith("https://") ||
    path.startsWith("http://") ||
    path.startsWith("data:image/")
  )
    return path;
  return `https://${process.env["PUBLIC_REGIONAL_DOMAIN_NAME"]}/${PUBLIC_IMAGES_PATHS[type]}${path}`;
};

/**
 * @description Convert any string to camel case
 * @param str The string to convert
 * @returns
 */
export const convertAnyStringToCamelCase = (str: string) => {
  return str
    .toLowerCase()
    .split("-")
    .join(" ")
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) =>
      index === 0 ? word.toLowerCase() : word.toUpperCase(),
    )
    .replace(/\s+/g, "");
};

/**
 * @description Retrieves a UUID.
 * @return A randomly generated UUID.
 */
export const getUUID = async () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore EdgeRuntime is not global in non-Edge environments.
  if (typeof EdgeRuntime !== "string") {
    const crypto = await import("crypto");
    return crypto.randomUUID();
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore In edge runtime, crypto is global.
  else return crypto.randomUUID();
};

/**
 * @description Get the IP address from the request headers
 * @param headers - Request headers
 * @returns IP address
 */
export const getIpAddress = (headers: Record<string, string | undefined>) => {
  let ip = headers["x-real-ip"];
  const forwardedFor = headers["x-forwarded-for"];
  if (!ip && forwardedFor) {
    ip = forwardedFor.split(",").at(0);
  }
  return ip;
};

/**
 * @description Get the location from an IP address
 * @param ip - IP address
 * @returns Location
 */
export const getLocationFromIp = async (ip: string) => {
  const response = await fetch(
    `http://ip-api.com/json/${ip}?fields=status,message,country,countryCode,region,regionName,city,zip,lat,lon,timezone,isp,org,as,query`,
  );
  const data = ipAPIResponseSchema.parse(await response.json());
  if (data.status === "fail") {
    console.error("Failed to get location from IP", data.message);
    return;
  }
  return data;
};

/**
 * @description Get the location from the request headers
 * @param headers - Request headers
 * @returns Location
 */
export const getLocationFromHeaders = async (
  headers: Record<string, string | undefined>,
) => {
  let country = headers["cloudfront-viewer-country"];
  let countryName = headers["cloudfront-viewer-country-name"];
  let city = headers["cloudfront-viewer-city"];
  let region = headers["cloudfront-viewer-region"];
  let regionName = headers["cloudfront-viewer-region-name"];
  let postalCode = headers["cloudfront-viewer-postal-code"];
  if (
    !country ||
    !countryName ||
    !city ||
    !region ||
    !regionName ||
    !postalCode
  ) {
    const ip = getIpAddress(headers);
    if (ip) {
      const location = await getLocationFromIp(ip);
      if (location) {
        country = location.countryCode;
        countryName = location.country;
        city = location.city;
        region = location.region;
        regionName = location.regionName;
        postalCode = location.zip;
      }
    }
  }
  return {
    country,
    countryName,
    city,
    region,
    regionName,
    postalCode,
  };
};

/**
 * @description Generate batches from an array
 * @param array - The array to generate batches from
 * @param batchSize - The size of each batch
 * @returns A generator that yields batches of the given array
 */
export function* batchGenerator<T>(array: Array<T>, batchSize: number = 10) {
  let i = 0;
  const arrayCopy = [...array];
  while (i < arrayCopy.length) {
    yield arrayCopy.slice(i, (i += batchSize));
  }
}

/**
 * @description Execute an array of promises with progress
 * @param promises - The promises to execute
 * @param progressCallback - The callback to execute on every promise
 * @returns The results of the promises
 */
export async function promiseAllSettledWithProgress<
  T = unknown,
  I = number | string,
>(
  promises: Array<{
    promise: Promise<T>;
    id: I;
  }>,
  progressCallback: (progress: number) => void,
): Promise<Array<PromiseSettledResult<T> & { id: I }>> {
  const results: Array<PromiseSettledResult<T> & { id: I }> = [];
  let settledPromises = 0;

  for (let i = 0; i < promises.length; i++) {
    const thePromise = promises[i];
    if (!thePromise) continue;
    const { promise, id } = thePromise;
    try {
      const result = await promise;
      results.push({ status: "fulfilled", value: result, id });
    } catch (error) {
      results.push({ status: "rejected", reason: error, id });
    } finally {
      settledPromises++;
      progressCallback(settledPromises);
    }
  }
  return results;
}

/**
 * @description Remove undefined values from an object by converting it to JSON and then parsing it back.
 * @param data - The object to remove undefined values from.
 * @example
 * const data = { name: 'John', age: 30, city: undefined};
 * const result = removeUndefinedFromObject(data);
 * // { name: 'John', age: 30};
 * console.log(result);
 * @returns The object with undefined values removed.
 */
export const removeUndefinedFromObject = <T>(
  data: T,
): Prettify<PickDefinedKeys<T>> => {
  return JSON.parse(JSON.stringify(data));
};

removeUndefinedFromObject({ name: "John", age: 30, city: undefined });
