/**
 * @description Get title formatted string
 * @example
 * // Returns "Hello World"
 * getTitleFormattedString("hello_world")
 * @example
 * // Returns "Hello World"
 * getTitleFormattedString("hello-world")
 * @example
 * // Returns "Hello World"
 * getTitleFormattedString("Hello World")
 * @example
 * // Returns "Hello World"
 * getTitleFormattedString("helloWorld")
 * @example
 * // Returns "Hello World"
 * getTitleFormattedString("hello world")
 * @param string - The string to format
 * @returns The formatted string
 */
export const getTitleFormattedString = (string: string) => {
  // Replace underscores and dashes with spaces, separate camel case, then capitalize the first letter of each word
  return string
    .replace(/[_-]/g, " ")
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()));
};

export const dateFormatter = ({
  date,
  locale = "en-US",
}: {
  date: Date;
  locale?: string;
}) => {
  return new Intl.DateTimeFormat(locale, {
    month: "long",
    day: "numeric",
    year: "numeric",
  }).format(date);
};

export const dateTimeFormatter = ({
  date,
  locale = "en-US",
}: {
  date: Date;
  locale?: string;
}) => {
  return new Intl.DateTimeFormat(locale, {
    month: "long",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
  }).format(date);
};

export const numberFormatter = ({
  value,
  locale,
}: {
  value: number;
  locale?: string;
}) => {
  return new Intl.NumberFormat(locale, {
    maximumFractionDigits: 2,
  }).format(value);
};

/**
 * @description Get the currency formatter for a given currency code
 * @param currencyCode - The currency code to use
 * @returns The currency formatter
 */
export const currencyFormatter = ({
  value,
  currencyCode = "DKK",
  locale = "en-US",
}: {
  value: number;
  currencyCode?: string;
  locale?: string;
}) => {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currencyCode,
    currencyDisplay: "narrowSymbol", // gives the symbol character for currencies. Ex: $ for USD, ₹ for INR, etc
    minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  }).format(value);
};
