import { z } from "zod";

import { appFieldsSchema } from "./availableApp";

export const iconSchema = z.object({
  data: z.string(),
  type: z.string(),
  filename: z.string().optional(),
  extension: z.string().optional(),
});

export const iconObjectSchema = z.object({
  icon: z.union([iconSchema, z.string().min(1)]),
});

const slugCommon = z
  .string()
  .min(2, { message: "Slug needs to be at least 2 characters long" })
  .max(255, { message: "Slug needs to be at most 256 characters long" });

export const slugSchema = slugCommon.regex(
  /^[a-z0-9]+(?:-[a-z0-9]+)*$/,
  "Slug can only contain lowercase letters, numbers, and dashes",
);

export const slugTransformSchema = slugCommon.transform((slug) =>
  encodeURIComponent(
    slug
      .trim()
      .toLowerCase()
      .replace(/[^a-z0-9-]+/gi, "-")
      .replace(/[^\w-]/g, ""),
  ),
);

export const oauth2AuthOptionsOptionsSchema = z.object({
  scopeSeparator: z
    .string()
    .optional()
    .default(" ")
    .describe(
      `Scope separator character. Some providers may require a different separator. Defaults to empty space`,
    ),
  credentialsEncodingMode: z
    .enum(["strict", "loose"])
    .default("strict")
    .optional()
    .describe(
      `Setup how credentials are encoded when options.authorizationMethod is header.
        Use loose if your provider doesn't conform to the OAuth 2.0 specification.
        Defaults to strict`,
    ),
  bodyFormat: z
    .enum(["form", "json"])
    .optional()
    .default("form")
    .describe(`Format of data sent in the request body. Defaults to form.`),

  authorizationMethod: z
    .enum(["header", "body"])
    .optional()
    .default("header")
    .describe(
      `Indicates the method used to send the client.id/client.secret authorization params at the token request.
        If set to body, the bodyFormat option will be used to format the credentials.
        Defaults to header`,
    ),
});

export const oauth2AuthOptionsScopeSchema = z.object({
  key: z.string(),
  description: z.string().optional(),
});

export const oauth2AuthOptionsScopesSchema = z
  .array(oauth2AuthOptionsScopeSchema)
  .describe(`Array of scopes that can be requested during authentication.`);

export const valueAskedSchema = z.object({
  key: z.string(),
  type: z.literal("string"),
  accepted: z.array(z.string()).optional(),
  default: z.string().optional(),
});

export const valuesAskedSchema = z
  .array(valueAskedSchema)
  .describe("Array of values needed to run the authentication flow.");

export const authOptionsOtherValueSchema = z.object({
  key: z.string(),
  value: z.string(),
});
export const authOptionsOtherValuesSchema = z.array(
  authOptionsOtherValueSchema,
);

export const oauth2AuthOptionsSchema = z
  .object({
    client: z.object({
      id: z
        .string()
        .describe(
          "Service registered client id. When required by the spec this value will be automatically encoded. Required.",
        ),
      secret: z
        .string()
        .describe(
          "Service registered client secret. When required by the spec this value will be automatically encoded. Required.",
        ),
      secretParamName: z
        .string()
        .optional()
        .default("client_secret")
        .describe(
          "Parameter name used to send the client secret. Defaults to client_secret.",
        ),
      idParamName: z
        .union([z.literal("client_id"), z.string()])
        .optional()
        .default("client_id")
        .describe(
          "Parameter name used to send the client id. Defaults to client_id.",
        ),
    }),
    auth: z
      .object({
        tokenHost: z
          .string()
          .describe("Base URL used to obtain access tokens. Required"),
        tokenPath: z
          .string()
          .optional()
          .default("/oauth/token")
          .describe(
            `URL path to obtain access tokens. Defaults to /oauth/token.
        Note: URL paths are relatively resolved to their corresponding host property using the Node WHATWG URL resolution algorithm`,
          ),
        refreshPath: z.string().optional()
          .describe(`URL path to refresh access tokens. Defaults to auth.tokenPath
    Note: URL paths are relatively resolved to their corresponding host property using the Node WHATWG URL resolution algorithm`),
        revokePath: z.string().optional().default("/oauth/revoke")
          .describe(`URL path to revoke access tokens. Defaults to /oauth/revoke
    Note: URL paths are relatively resolved to their corresponding host property using the Node WHATWG URL resolution algorithm
    `),
        authorizeHost: z
          .string()
          .optional()
          .describe(
            "Base URL used to request an authorization code. Only valid for AuthorizationCode. Defaults to auth.tokenHost ",
          ),
        authorizePath: z.string().optional().default("/oauth/authorize")
          .describe(`URL path to request an authorization code. Only valid for AuthorizationCode. Defaults to /oauth/authorize
     Note: URL paths are relatively resolved to their corresponding host property using the Node WHATWG URL resolution algorithm`),
      })
      .transform((auth) => {
        if (!auth.refreshPath) auth.refreshPath = auth.tokenPath;
        if (!auth.authorizeHost) auth.authorizeHost = auth.tokenHost;
        if (!auth.revokePath) auth.revokePath = auth.tokenPath;
        return auth;
      }),
    valuesAsked: valuesAskedSchema.optional(),
    scopes: oauth2AuthOptionsScopesSchema.optional(),
    params: z
      .record(z.string())
      .optional()
      .default({})
      .describe(
        `Additional parameters required by the service.
      For example:
      - access_type: Google OAuth2 access type`,
      ),
    options: oauth2AuthOptionsOptionsSchema.optional().default({}),
    otherValues: authOptionsOtherValuesSchema.optional(),
  })
  .transform((authOptions, ctx) => {
    const valueKeysDefined: Array<string> = [];

    // Find all ${key} in authOptions.auth and add key to valueKeysDefined
    for (const key in authOptions.auth) {
      // Match all ${key} in authOptions.auth
      const matches =
        authOptions.auth[key as keyof typeof authOptions.auth]?.match(
          /\${(.*?)}/g,
        );
      for (const match of matches ?? []) {
        // Remove ${ and }
        const valueKey = match.slice(2, -1).trim();
        if (!valueKeysDefined.includes(valueKey)) {
          valueKeysDefined.push(valueKey);
        }
      }
    }
    // Check if valuesAsked has key for all valueKeysDefined and add issue if not
    for (const valueKeyDefined of valueKeysDefined) {
      if (
        !authOptions.valuesAsked?.find((value) => value.key === valueKeyDefined)
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `valuesAsked doesn't have key for ${valueKeyDefined}`,
          path: ["valuesAsked"],
        });
      }
    }

    return authOptions;
  });

export const customAuthOptionsSchema = z.object({
  otherValues: authOptionsOtherValuesSchema.optional(),
});

export const fieldsValuesSchema = z
  .record(
    z.object({
      value: z.string(),
      hidden: z.boolean().optional(),
    }),
  )
  .default({});

export const fieldsValuesRemoveHiddenTransform = fieldsValuesSchema.transform(
  (fieldsValues) => {
    const fieldsValuesWithoutHidden: Record<string, { value: string }> = {};
    for (const key in fieldsValues) {
      const field = fieldsValues[key];
      if (field && !field.hidden) {
        fieldsValuesWithoutHidden[key] = {
          value: field.value,
        };
      }
    }
    return fieldsValuesWithoutHidden;
  },
);

/**
 * @description Transform fieldsValues to have default values if not set and check if all required fields are set
 */
export const fieldsValuesTransform = z
  .object({
    appFields: appFieldsSchema,
    fieldsValues: z.record(
      z
        .object({
          value: z.string().optional(),
          hidden: z.boolean().optional(),
        })
        .optional(),
    ),
  })
  .transform((data, ctx) => {
    const appFields = data.appFields;
    const fieldsValues = data.fieldsValues;
    for (const field of appFields) {
      if (!fieldsValues[field.key]?.value && field.defaultValue) {
        fieldsValues[field.key] = {
          value: field.defaultValue,
          hidden: field.hiddenFromUI,
        };
      }
      if (
        field.required &&
        !fieldsValues[field.key]?.value &&
        field.askFromUser
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `Missing required field ${field.key}`,
          path: [field.key],
        });
      }
    }
    return fieldsValues;
  });

export const fieldsValuesWithDefaultTransform = z
  .object({
    appFields: appFieldsSchema,
    fieldsValues: z.record(
      z
        .object({
          value: z.string().optional(),
          hidden: z.boolean().optional(),
        })
        .optional(),
    ),
  })
  .transform((data) => {
    const appFields = data.appFields;
    const fieldsValues = data.fieldsValues;
    for (const field of appFields) {
      if (!fieldsValues[field.key]?.value && field.defaultValue) {
        fieldsValues[field.key] = {
          value: field.defaultValue,
          hidden: field.hiddenFromUI,
        };
      }
    }
    return fieldsValues;
  });

export const ipAPIResponseSchema = z.union([
  z.object({
    query: z.string(),
    status: z.literal("success"),
    country: z.string(),
    countryCode: z.string(),
    region: z.string(),
    regionName: z.string(),
    city: z.string(),
    zip: z.string(),
    lat: z.number(),
    lon: z.number(),
    timezone: z.string(),
    isp: z.string(),
    org: z.string(),
    as: z.string(),
  }),
  z.object({
    query: z.string(),
    status: z.literal("fail"),
    message: z.string(),
  }),
]);

export const errorSchema = z.object({
  name: z.string(),
  message: z.string(),
  stack: z.string().optional(),
  cause: z.unknown().optional(),
  code: z.string().optional(),
  zodFriendlyMessage: z.string().nullish(),
  issues: z
    .array(
      z.object({
        message: z.string(),
        code: z.string(),
        path: z.array(z.union([z.string(), z.number()])),
      }),
    )
    .optional(),
});

export const referenceSchema = z.object({
  id: z.string(),
  label: z.string(),
  value: z.string(),
});
